import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import LogoutButton from './LogoutButton';
import EventForm from './EventForm';

function Dashboard() {
    const [selectedSection, setSelectedSection] = useState(null);
    const sections = [
        { id: 1, name: 'Ongoing Event' },
        { id: 2, name: 'Notice Board' },
    ];

    useEffect(() => {
        if (sections.length > 0) {
            setSelectedSection(sections[0].id);
        }
    }, []);

    const handleSectionClick = (sectionId) => {
        setSelectedSection(sectionId);
    };

    return (
        <div>
            <div className="campus-life-container">
                <div className="sections-list">
                    {sections.map(section => (
                        <div
                            key={section.id}
                            className={`section-item ${selectedSection === section.id ? 'selected' : ''}`}
                            onClick={() => handleSectionClick(section.id)}
                        >
                            {section.name}
                        </div>
                    ))}
                    <LogoutButton />
                </div>

                <div className="section-details">
                    {selectedSection === 1 && (
                        <EventForm />
                    )}
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
