import '../../App.css';
import React, { useEffect, useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';

const Heading = () => {
    const logo = 'images/logo.jpg';
    const pdkvLogo = 'images/pdkv-removebg.png';
    const geetai = 'images/geetai.png';

    const [scroll, setScroll] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            setScroll(window.scrollY > 0);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    const getActiveClass = (path) => (location.pathname === path ? 'active' : '');

    return (
        <div className='text-overlay-main'>
            <div className='text-overlay'>
                <div className='flex'>
                    <img className='header-logo' src={process.env.PUBLIC_URL + logo} alt='logo' />
                    <img className='header-logo display-none' src={process.env.PUBLIC_URL + pdkvLogo} alt='logo' />
                </div>
                <div className='header-content col-7'>
                    <div className='sub-content-container'>
                        <img src={process.env.PUBLIC_URL + geetai} alt='geetai' />
                        <div className='sub-content1'>Geetai Humankind Development Trust’s</div>
                    </div>
                    <div className='sub-content2'>
                        Karmayogi Babaraoji Jogdand College of Agriculture
                    </div>
                    <div className='sub-content3'>
                        (Ahinsatirth) Amkheda Tq. Malegaon Dist. Washim
                    </div>
                    <div className='sub-content3 affiliation'>
                        Affiliated to Dr. Panjabrao Deshmukh Krishi Vidyapeeth, Akola.
                    </div>
                </div>
                <img className='header-logo responsive' src={process.env.PUBLIC_URL + pdkvLogo} alt='logo' />
            </div>

            {/* Navbar */}
            <div>
                <nav className={`navbars ${scroll ? 'white-background' : ''}`}>
                    <div className={`ul ${menuOpen ? 'show' : ''}`}>
                        <button className={getActiveClass('/')} onClick={closeMenu}>
                            <Link to="/" className='navigation-links'>Home</Link>
                        </button>
                        <button className={getActiveClass('/aboutCollege')} onClick={closeMenu}>
                            <Link to="/aboutCollege" className='navigation-links'>About Us</Link>
                        </button>
                        <button className={getActiveClass('/admissions')} onClick={closeMenu}>
                            <Link to="/admissions" className='navigation-links'>Admissions</Link>
                        </button>
                        <button className={getActiveClass('/courses')} onClick={closeMenu}>
                            <Link to="/courses" className='navigation-links'>Courses</Link>
                        </button>
                        <button className={getActiveClass('/campusLife')} onClick={closeMenu}>
                            <Link to="/campusLife" className='navigation-links'>Campus Life</Link>
                        </button>
                        <button className={getActiveClass('/noticeBoard')} onClick={closeMenu}>
                            <Link to="/noticeBoard" className='navigation-links'>Notice Board</Link>
                        </button>
                        <button className={getActiveClass('/contactUs')} onClick={closeMenu}>
                            <Link to="/contactUs" className='navigation-links'>Contact Us</Link>
                        </button>
                    </div>
                    <div className='menu-icon' onClick={toggleMenu}>
                        <FaBars/>
                    </div>
                </nav>
            </div>
        </div>
    );
};

export default Heading;
