import React, { useState } from 'react';
// import './OngoingEventForm.css';

const EventForm = () => {
    const [events, setEvents] = useState([]);
    const [formData, setFormData] = useState({
        title: '',
        date: '',
        description: '',
        image: null
    });
    const [editingIndex, setEditingIndex] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            image: e.target.files[0]
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (editingIndex !== null) {
            const updatedEvents = [...events];
            updatedEvents[editingIndex] = formData;
            setEvents(updatedEvents);
            setEditingIndex(null);
        } else {
            setEvents([...events, formData]);
        }

        setFormData({
            title: '',
            date: '',
            description: '',
            image: null
        });

        alert('Event submitted successfully!');
    };

    const handleEdit = (index) => {
        setEditingIndex(index);
        setFormData(events[index]);
    };

    const handleDelete = (index) => {
        const updatedEvents = events.filter((_, i) => i !== index);
        setEvents(updatedEvents);
    };

    return (
        <div>
            <form onSubmit={handleSubmit} className="event-form">
                <div>
                    <label>Event Title</label>
                    <input
                        type="text"
                        name="title"
                        value={formData.title}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div>
                    <label>Date</label>
                    <input
                        type="date"
                        name="date"
                        value={formData.date}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div>
                    <label>Event Description</label>
                    <textarea
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div>
                    <label>Event Image</label>
                    <input
                        type="file"
                        name="image"
                        accept=".jpg, .jpeg, .png"
                        onChange={handleFileChange}
                        required
                    />
                </div>
                <button type="submit">Submit</button>
            </form>

            <div className="event-list">
                {events.map((event, index) => (
                    <div key={index} className="event-item">
                        <h3>{event.title}</h3>
                        <p>{event.date}</p>
                        <p>{event.description}</p>
                        {event.image && (
                            <img
                                src={URL.createObjectURL(event.image)}
                                alt={event.title}
                                className="event-image"
                            />
                        )}
                        <button onClick={() => handleEdit(index)}>Edit</button>
                        <button onClick={() => handleDelete(index)}>Delete</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EventForm;
