import '../../App.js'

function Footer() {
  return (
    <div className='footer'>
        <p>Copyright © 2024-25 Agriculture College. Designed and Developed by Myospaz Software Technologies</p>
    </div>
  )
}

export default Footer
