import React from 'react';
import { useNavigate } from 'react-router-dom';
import './LogoutButton.css';

const LogoutButton = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        const confirmed = window.confirm('Are you sure you want to log out?');
        if (confirmed) {
            localStorage.removeItem('isAuthenticated');
            alert('You have been logged out successfully.');
            navigate('/adminLogin');
        }
    };

    return (
        <button type='submit' onClick={handleLogout} className="logout-button">
            Log Out
        </button>
    );
};

export default LogoutButton;
